import React, { useState, useRef, useEffect } from "react";
import Tesseract from "tesseract.js";
import axios from "axios";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import products from "./products.json";
import retailList from "./retailList.json";
import ThanksMessage from "./thanksMessage/thanksMessage";
import moment from "moment";
// import Select from "react-select"
import { BASE_URL, RECAPTCHA_SITE_KEY, ENV } from "gatsby-env-variables";
import ReceiptsUserGuideModal from "./receiptsUserGuideModal/receiptsUserGuideModal";

const Form = () => {
  // const[modal, setModal] = useState(false);

  // useEffect(() => {
  //  if(modal){
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   setModal(false);
  //  }
  // }, [modal])

  const [uploadError, setUploadError] = useState(false);
  const [chooseFile, setChooseFile] = useState({
    isFileScanning: false,
    isFileScanned: false,
  });
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadReceiptError, setUploadReceiptError] = useState(false);
  const [duplicateReceiptError, setDuplicateReceiptError] = useState(false);
  const [uploadProperReceiptError, setUploadProperReceiptError] =
    useState(false);
  const [uploadReceiptFormatError, setUploadReceiptFormatError] =
    useState(false);
  const [uploadReceiptSizeError, setUploadReceiptSizeError] = useState(false);
  const [invalidReceiptErrorMsg, setInvalidReceiptErrorMsg] = useState(false);

  const [captchaError, setCaptchaError] = useState(false);
  const [captchaClicked, setCaptchaClicked] = useState(false);
  const [captchaClickedError, setCaptchaClickedError] = useState(false);

  const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [fileNameKey, setFileNameKey] = useState(null);
  const [file, setFile] = useState(null);
  const [tessText, setTessText] = useState(null);
  const [tessScanTextArray, setTessScanTextArray] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [thanksMsg, setThanksMsg] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(null);
  // const [productListErr, setProductListErr] = useState(false);
  const [oneDayValidationErrorMsg, setOneDayValidationErrorMsg] =
    useState(false);
  const [duplicateWinnerErrorMsg, setDuplicateWinnerErrorMsg] = useState(false);

  const recaptchaRef = useRef();
  const fileRef = useRef();

  // File Input Drag and Drop Functionality
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    setDuplicateReceiptError(false);
    setUploadError(false);
    setUploadReceiptError(false);
    setUploadProperReceiptError(false);
    setUploadReceiptFormatError(false);
    setUploadReceiptSizeError(false);
    setIsUploadBtnDisabled(true);
    setFile(null);
    setTessText(null);
    setTessScanTextArray(null);
    setImageUrl(e.target.value);
    const newFile = e.target.files[0];
    if (newFile === undefined) {
      setUploadReceiptError(true);
    }
    if (newFile && newFile.name.match(/.(jpg|jpeg|png|gif)$/i)) {
      if (newFile.size < 268435456) {
        setFile(newFile);
        Tesseract.recognize(
          e.target.files[0],
          "eng",
          {
            logger: (m) => {},
          },
          setChooseFile({ isFileScanned: false, isFileScanning: true })
        ).then((resp) => {
          console.log(resp, 'res')
          const {
            data: { text },
          } = resp;
          setChooseFile({ isFileScanned: false, isFileScanning: false });
          if (text === "") {
            setTessText("");
            setTessScanTextArray("");
            setImageUrl("");
            setUploadProperReceiptError(true);
          } else {
            setTessText(text);
            setTessScanTextArray(resp?.data?.lines);
            setIsUploadBtnDisabled(false);
            setImageUrl(e.target.value);
          }
        });
      } else {
        setUploadReceiptSizeError(true);
      }
    } else {
      setUploadReceiptFormatError(true);
    }
  };

  const fileUpload = (event) => {
    event.preventDefault();
    setChooseFile({ isFileScanned: false, isFileScanning: false });
    if (file) {
      const fileData = {
        file: file,
      };
      var fileUploadConfig = {
        method: "post",
        url: `${BASE_URL}/upload`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: fileData,
      };
      axios(fileUploadConfig)
        .then((response) => {
          // setIsSubmitBtnDisabled(false)
          setIsFileUploaded(true);
          setUploadError(false);
          setFileNameKey(response.data.result.key);
        })
        .catch((e) => {
          setUploadError(true);
          setImageUrl("");
        });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const productsArray = products.map((product) => {
  //   return { value: product, label: product }
  // })

  // const handleChange = e => {
  //   setSelectedOption(e);
  //   setProductListErr(false);
  // }

  const handleRecaptcha = () => {
    // setCaptchaClickedError(false);
    setCaptchaClicked(true);
    setCaptchaError(false);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (captchaClicked === false) {
      setCaptchaClickedError(true);
    }
    setChooseFile({ isFileScanned: true, isFileScanning: false });

    const captchaToken = await recaptchaRef.current.getValue();
    data.text = tessText;
    data.file = file;

    if (uploadReceiptError || chooseFile.isFileScanned) {
      fileRef && fileRef.current && fileRef.current.focus();
    }
    if (tessText === null) {
      setUploadReceiptError(true);
      recaptchaRef.current.reset();
      setCaptchaClicked(false);
    } else if (tessText === "") {
      setUploadProperReceiptError(true);
      recaptchaRef.current.reset();
      setCaptchaClicked(false);
    } else {
      // setProductListErr(false);
      if (captchaToken && isFileUploaded) {
        setChooseFile({ isFileScanned: false, isFileScanning: false });
        // map captured data to form data to send
        const aTessScanTextArray = tessScanTextArray.map((arr) => arr.text);
        const formData = {
          first_name: data.firstName,
          last_name: data.lastName,
          email_id: data.email,
          mobile_number: data.mobileNumber,
          dob: data.dob,
          product: data.product1.concat(",").concat(data.product2),
          receipt_content_lines: aTessScanTextArray,
          receipt_content: data.text,
          receipt_name: fileNameKey,
          token: captchaToken,
          marketing_optin: data.privacy === true ? 1 : 0,
          retailer: data.retailer,
        };
        var winnerConfig = {
          method: "post",
          // Stage
          // url: "https://stage.api.chillorthrill.marspromotions.com.au/api/v1/lucky-winner/",
          // Production
          url: `${BASE_URL}/user-validation`,
          // headers: {
          //   // Authorization: `Bearer ${accessToken}`,
          //   "Content-Type": "multipart/form-data",
          // },
          data: formData,
        };
        // Send Data to API
        axios(winnerConfig)
          .then((response) => {
            if (response.data.error === false) {
              setThanksMsg(true);
            } else if (
              response.data.error === true &&
              response.data.error_type === "Token"
            ) {
              setCaptchaError(true);
              recaptchaRef.current.reset();
            } else if (
              response.data.error === true &&
              response.data.error_type === "24Hr"
            ) {
              setOneDayValidationErrorMsg(true);
              setThanksMsg(true);
            } else if (
              response.data.error === true &&
              response.data.error_type === "ValidateReceipt" &&
              response.data.message ===
                "Oops, looks like this receipt is already validated. Please try another valid receipt."
            ) {
              recaptchaRef.current.reset();
              setDuplicateReceiptError(true);
              setIsFileUploaded(false);
              setCaptchaClicked(false);
              setImageUrl("");
            } else if (
              response.data.error === true &&
              response.data.error_type === "ValidateReceipt" &&
              response.data.message === "Please upload valid receipt."
            ) {
              setInvalidReceiptErrorMsg(true);
              setThanksMsg(true);
            } else if (
              response.data.error === true &&
              response.data.error_type === "DuplicateWinner"
            ) {
              setDuplicateWinnerErrorMsg(true);
              setThanksMsg(true);
            } else {
              setThanksMsg(true);
            }
          })
          .catch((err) => {
            recaptchaRef.current.reset();
            setCaptchaError(false);
          });
      }
    }
  };

  const onErrors = (obj, e) => {
    setTimeout(() => {
      console.log("Form has errors");
      window.dispatchEvent(new Event("resize"));
    }, 800);
  };

  return (
    <>
      {!thanksMsg && (
        <div>
          <div>
            <h1 className="form-header">Enter Here</h1>
          </div>
          <div className="paragraph">
            <p>
              Purchase any two participating Eclipse products & enter your
              details to claim your 2-for-1 Funlab Coupon. Plus, go into the
              draw to WIN 1 of 5 $5,000 FUN weekends for two*
            </p>
          </div>
          <div className="signup-form-wrap">
            <form
              className="globalClass"
              onSubmit={handleSubmit(onSubmit, onErrors)}
            >
              <div className="input-fields">
                <input
                  type="text"
                  className="globalElement"
                  id="firstName"
                  name="firstName"
                  placeholder="FIRST NAME^"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <span className="inputError">First Name is required</span>
                )}
              </div>
              <div className="input-fields">
                <input
                  type="text"
                  className="globalElement"
                  id="lastName"
                  name="lastName"
                  placeholder="LAST NAME^"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <span className="inputError">Last Name is required</span>
                )}
              </div>
              <div className="input-fields">
                <input
                  type="email"
                  className="globalElement"
                  id="email"
                  name="email"
                  placeholder="EMAIL ADDRESS^"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                {errors.email && (
                  <span className="inputError">{errors.email.message}</span>
                )}
              </div>
              <div className="input-fields">
                <input
                  className="globalElement"
                  type="text"
                  id="dob"
                  name="dob"
                  placeholder="DATE OF BIRTH^"
                  onFocus={(e) => (e.target.type = "date")}
                  {...register("dob", {
                    required: true,
                    max: moment()
                      .subtract(18, "year")
                      .toISOString()
                      .split("T")[0],
                  })}
                />
                {errors?.dob?.type === "required" && (
                  <span className="inputError">Date of Birth is required</span>
                )}
                {errors?.dob?.type === "max" && (
                  <span className="inputError">
                    Age should be 18 years or above
                  </span>
                )}
              </div>
              <div className="input-fields">
                <input
                  type="text"
                  className="globalElement"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="MOBILE NUMBER^"
                  {...register("mobileNumber", {
                    required: "Mobile Number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid mobile number",
                    },
                  })}
                />
                {errors.mobileNumber && (
                  <span className="inputError d-block">
                    {errors.mobileNumber.message}
                  </span>
                )}
              </div>
              <div className="form-select-drop">
                <select
                  className="form-select"
                  id="product1"
                  name="product1"
                  aria-label="Product Purchased"
                  {...register("product1", { required: true })}
                >
                  <option value="" key="2">
                    Please select participating product #1^
                  </option>
                  {products.map((product) => (
                    <>
                      <option key={product} value={product}>
                        {product}
                      </option>
                    </>
                  ))}
                </select>
                {errors.product1 && (
                  <span className="inputError d-block">
                    Product Purchased #1 is required
                  </span>
                )}
              </div>
              <div className="form-select-drop">
                <select
                  className="form-select"
                  id="product2"
                  name="product2"
                  aria-label="Product Purchased"
                  {...register("product2", { required: true })}
                >
                  <option value="" key="1">
                    Please select participating product #2^
                  </option>

                  {products.map((product) => (
                    <>
                      <option key={product} value={product}>
                        {product}
                      </option>
                    </>
                  ))}
                </select>
                {errors.product2 && (
                  <span className="inputError d-block">
                    Product Purchased #2 is required
                  </span>
                )}
              </div>
              <div className="form-select-drop">
                <select
                  className="form-select"
                  id="retailer"
                  name="retailer"
                  aria-label="Retailer"
                  {...register("retailer", { required: true })}
                >
                  <option value="" key="3">
                    Retailer^
                  </option>
                  {retailList.map((list) => (
                    <>
                      <option key={list} value={list}>
                        {list}
                      </option>
                    </>
                  ))}
                </select>
                {errors.retailer && (
                  <span className="inputError d-block">
                    Retailer is required
                  </span>
                )}
              </div>
              <label className="form-label" htmlFor="file">
                UPLOAD UNIQUE RECIEPT^
              </label>
              <div className="upload-file">
                {isFileUploaded && !uploadError ? (
                  <>
                    <div className="drop-file-preview">
                      <p>{file.name}</p>
                    </div>
                    <button
                      className="upload-button"
                      onClick={() => {
                        //move this block to some const
                        //if file is uploaded in S3, delete logic goes here
                        setIsFileUploaded(false);
                        setChooseFile({
                          isFileScanned: false,
                          isFileScanning: false,
                        });
                        setFile(null);
                        setTessText(null);
                        setTessScanTextArray(null);
                        setImageUrl("");
                        setIsUploadBtnDisabled(true);
                      }}
                    >
                      REMOVE
                    </button>
                  </>
                ) : (
                  <>
                    <div>
                      <div
                        className="drop-file-input"
                        ref={wrapperRef}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                      >
                        <input
                          type="file"
                          id="formFile"
                          name="file"
                          ref={fileRef}
                          className="globalElement"
                          onChange={onFileDrop}
                          value={imageUrl}
                          accept="image/png, image/jpeg, image/bmp, image/pbm"
                          multiple={false}
                        />
                      </div>
                      {uploadError && (
                        <span className="inputError d-block">
                          Please try re-uploading the file
                        </span>
                      )}
                      {uploadReceiptError && (
                        <span className="inputError d-block">
                          Upload Receipt is required
                        </span>
                      )}
                      {uploadProperReceiptError && (
                        <span className="inputError d-block">
                          Please upload a valid receipt that clearly shows the
                          two (2) Eclipse products you have purchased.
                        </span>
                      )}
                      {!uploadReceiptError && uploadReceiptFormatError && (
                        <span className="inputError d-block">
                          Please upload receipt image in support format: gif,
                          jpg, jpeg, png
                        </span>
                      )}
                      {uploadReceiptSizeError && (
                        <span className="inputError d-block">
                          {/* Limit Exceeded (File size below 256MB) */}
                          Please upload receipt size below 256MB
                        </span>
                      )}
                      {duplicateReceiptError && (
                        <span className="inputError d-block">
                          Oops, looks like this receipt is already validated.
                          Please try another valid receipt.
                        </span>
                      )}
                      {chooseFile.isFileScanning && (
                        <p className="upload-warning-msg">
                          Please wait, receipt scanning is in progress...
                        </p>
                      )}
                      {!chooseFile.isFileScanning &&
                        chooseFile.isFileScanned &&
                        !uploadReceiptFormatError &&
                        !uploadReceiptError &&
                        !uploadProperReceiptError && (
                          <p className="inputError">
                            Please click on upload, to upload your file
                          </p>
                        )}
                      {!uploadError &&
                        !uploadReceiptError &&
                        !uploadProperReceiptError &&
                        !uploadReceiptSizeError &&
                        !duplicateReceiptError &&
                        !chooseFile.isFileScanning &&
                        !chooseFile.isFileScanned &&
                        isUploadBtnDisabled && (
                          <p className="upload-warning-msg">
                            Please make sure the receipt you are uploading is
                            properly oriented and clearly visible.{" "}
                            <button
                              type="button"
                              className="modal-popup-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              // onClick={() => setModal(true)}
                            >
                              Click here
                            </button>{" "}
                            to refer sample receipt.
                          </p>
                        )}
                    </div>
                    <div>
                      <button
                        className={
                          isUploadBtnDisabled
                            ? "upload-button-disabled"
                            : "upload-button"
                        }
                        onClick={(e) => fileUpload(e)}
                        disabled={isUploadBtnDisabled}
                      >
                        UPLOAD
                      </button>
                    </div>
                  </>
                )}
              </div>
              <p>^ Mandatory Fields</p>
              <div className="caption-block">
                <p className="caption">One file only.</p>
                <p className="caption">256 MB limit.</p>
                <p className="caption">Allowed types: gif, jpg, jpeg, png.</p>
              </div>
              <div className="">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="terms"
                    {...register("terms", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="terms">
                    I am 18 years of age or older and I have read and accept the{" "}
                    <a
                      href="/terms.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ color: "#00AB69" }}
                    >
                      Terms and Conditions
                    </a>
                    . *
                  </label>
                </div>
                {errors.terms && (
                  <span className="inputError d-block mb-3">
                    Please tick this box if you want to proceed
                  </span>
                )}
              </div>
              <div className="">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="maxEntry"
                    {...register("maxEntry", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="maxEntry">
                    I understand that I must retain receipt(s) as proof of
                    purchase and the use of receipt(s) more than once will
                    render all my entries invalid. I understand that a
                    photograph of the product will NOT be accepted instead of a
                    receipt.
                  </label>
                </div>
                {errors.maxEntry && (
                  <span className="inputError d-block mb-3">
                    Please tick this box if you want to proceed
                  </span>
                )}
              </div>
              <div className="">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="privacy"
                    {...register("privacy", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="maxEntry">
                    It is a condition of entry that entrants consent to
                    receiving marketing materials. We’d like to keep you up to
                    date with the exciting promotions and the new product
                    developments from Mars Bars and other Mars Brands. You may
                    change these preferences at any time by contacting{" "}
                    <a
                      href="mailto:privacy@effem.com.au"
                      target="_blank"
                      style={{ color: "#00AB69" }}
                    >
                      privacy@effem.com.au
                    </a>
                    . From time to time, we may use your data for research to
                    enhance our product and service offerings. You can find out
                    how Mars Confectionery and its affiliates collects and
                    processes your data, contact us with your privacy questions,
                    and exercise your personal data rights via the{" "}
                    <a
                      href="https://www.mars.com/privacy"
                      target="_blank"
                      style={{ color: "#00AB69" }}
                    >
                      Mars Privacy Policy
                    </a>
                    .
                  </label>
                </div>
                {errors.privacy && (
                  <span className="inputError d-block ms-1 mb-2">
                    Please tick this box if you want to proceed
                  </span>
                )}
              </div>
              <div className="recaptcha">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  ref={recaptchaRef}
                  asyncScriptOnLoad={() => {
                    window.dispatchEvent(new Event("resize"));
                  }}
                  onChange={() => handleRecaptcha()}
                />
              </div>
              {captchaClickedError && !captchaClicked && (
                <p className="inputError text-center mt-1">
                  Recaptcha is required
                </p>
              )}
              {captchaError && (
                <p className="inputError text-center mt-1">
                  Sorry, looks to be an issue, please try resubmitting.
                </p>
              )}
              <div>
                <button
                  type="submit"
                  className="submit-button"
                  onClick={() => setSubmitBtnClicked(!submitBtnClicked)}
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {thanksMsg && (
        <ThanksMessage
          invalidReceiptErrorMsg={invalidReceiptErrorMsg}
          oneDayValidationErrorMsg={oneDayValidationErrorMsg}
          duplicateWinnerErrorMsg={duplicateWinnerErrorMsg}
        />
      )}
      <div id="disc-container">
        <p className="disclaimer">
          *AU18+ only. Ends 29/5/24 11:59pm AEST. To enter, a) purchase 2
          Eclipse products in 1 transaction from any store within Australia
          displaying advertising for this promotion (including online); and b)
          visit www.eclipsemints.com.au/ twicethefun to complete the entry form,
          including upload the receipt, and consent to receive marketing
          materials. Keep receipt/s. Limit 1 entry/person/day. Max 1
          entry/transaction. Max 1 Gift p/person & max 1 Prize p/person (excl.
          SA). Prize Draw: 2pm AEST, 6/6/24, Level 4, 411 Collins Street,
          Melbourne VIC 3000. Prize: 5 winners receive 1 x $4,000 VISA Digital
          gift card & 1 x $1,000 Funlab Gift Card. Gift (available to all
          entrants): a 2-for-1 Funlab discount voucher (valued up to $32). Prize
          winners published at www.eclipsemints.com.au/ twicethefun by 13/6/24.
          Promoter: Mars Australia Pty Ltd t/as Mars Wrigley Confectionery
          Australia ABN 48 008 454 313, Tower 2, Collins Square, 727 Collins St,
          Docklands VIC 3088. Full T&CS: www.eclipsemints.com.au/ twicethefun.
          NSW Authority No. TP/02036. Permit No's: ACT TP 23/01186 & SA T23/920.
        </p>
        <p className="faq-section">
          <a className="decoration" href="/terms.pdf" target="_blank">
            *TERMS & CONDITIONS
          </a>{" "}
          |{" "}
          <a className="decoration" href="/faqs.pdf" target="_blank">
            FAQs
          </a>{" "}
          |{" "}
          <a
            className="decoration"
            href="mailto:eclipsetwicethefun@xpobrands.com.au"
            target="_blank"
          >
            CONTACT US
          </a>
        </p>
        <ReceiptsUserGuideModal />
      </div>
    </>
  );
};

export default Form;
